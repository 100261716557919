// TeachingStaff.scss
$main-background : rgb(6, 53, 85);
$secondry-background: rgb(7, 135, 158);
$ternary-background: rgb(255, 120, 31);    

.teaching-staff {
  background-color: rgb(241, 241, 241);
  padding: 2rem;
  min-height: 100vh;

  h1 {
    text-align: center;
    color: black;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

  .staff-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
}

.profile-card {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(95, 95, 95, 0.1);
  display: flex;
  flex-direction: column;

  .header {
    background: linear-gradient(90deg, $main-background, $secondry-background);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    .avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      border: 4px solid white;
      position: relative;
      top:30px;
        
      .staff-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .content {
    padding: 3rem 1rem 1.5rem 1rem;
    text-align: center;
    flex-grow: 1;

    h2 {
      margin: 0;
      font-size: 1.25rem;
      color: black;
    }

    .position {
      margin: 0.5rem 0;
      font-size: 1rem;
      color: lighten(Black, 20%);
      font-style: italic;
    }
    .underline {
        width:150px;
        height:3px;
        background-color: $ternary-background;
    }

    .description {
      margin: 0.6rem 0 0;
      font-size: 0.9rem;
      color:black;
      line-height: 1.4;
    }
  }


  position: relative;
  overflow: hidden;

  .star-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 0;
  }

  .star {
    position: absolute;
    // background-color: $ternary-background;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    opacity: 0.3;
    animation: twinkle 5s infinite;

    @for $i from 1 through 50 {
      &:nth-child(#{$i}) {
        top: random(100) * 1%;
        left: random(100) * 1%;
        animation-delay: random(5000) * 1ms;
      }
    }
  }
}
  