
@import './Utills.scss';
@import './Color.scss'; 
 
header {
    width: 100%;
    // padding:2
    .top {
        background: $main-background;
        padding:10px 20px;
        @include display_as_row_space_around();
        transition: background-color 0.3s ease;

        &.scrolled {
          background: rgba(0, 0, 0, 0.8);
          position: fixed;
          top: 0;
          z-index: 1000;
        }
        .call_us {
            background-color: transparent;

            button{
                background-color: transparent;
                outline: none;
                border:none;
                margin-left: 10px;
                cursor: pointer;

            }

            img{
                width: 40px;
                border-radius: 50%;
                
            }
        }

        .social_media_links {
           a{
            padding: 10px;
            img{
                max-width: 80px;
                max-height: 30px;
            }
           }
        } 
    }
    .center{ 
        background-color: $secondry-background;
        // background: linear-gradient(110deg, rgb(4, 77, 90) , rgb(22, 96, 109) 10%, rgb(4, 81, 95));
        // background-color: $secondry-background;
//         background: #4b6cb7;  /* fallback for old browsers */
// background: -webkit-linear-gradient(to right, #182848, #4b6cb7);  /* Chrome 10-25, Safari 5.1-6 */
// background: linear-gradient(to right, #182848, #4b6cb7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        // animation: animateCenter 2s 2s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
        @include display_as_row_space_around();
        // padding:20px;
        .logo_name {
            @include display_as_row_center();
            img {
                width : 80px;
                border-radius: 50%;
                transition: transform 0.3s ease;
            }
            span{
                color: white;
                text-align: center;
                padding:5px;

                h1{
                    font-family: 'Inter', sans-serif;
                    font-size: 25px;
                    font-weight: bold;
                    color:rgb(255, 122, 34);
                    text-shadow: 1px 1px 2px black;
                }

                p{
                    // font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                    font-size: 19px;
                }
            }
        }
     
        .years_of_service{
            img{
                width:100px;
                padding-top: 10px;
            }
        }
    }

    // @keyframes animateCenter {
    //     0%{
    //         background: linear-gradient(90deg, rgb(4, 77, 90), rgb(15, 145, 168), rgb(4, 81, 95));

    //     }
    //     50%{
    //         background: linear-gradient(90deg,rgb(4, 81, 95), rgb(4, 81, 95),  rgb(15, 145, 168));

    //     }
    //     100%{
    //         background: linear-gradient(90deg, rgb(4, 77, 90), rgb(15, 145, 168), rgb(4, 81, 95));

    //     }
    // }

    .bottom {
        background-color: $main-background;
        position: relative;
        z-index: 21;
        @include display_as_row_center();

        button{
            font-size: 19px;
            padding: 1px 10px;
            background-color: white;
            outline: none;
            border:none; 
            border-radius: 10px;
            font-weight: bold;
            cursor: pointer; 
            display: none;
                @include display_as_column_center();
            span{
                @include display_as_column_center();
            }
        }

        nav{
          @include display_as_row_center();
         
          section {
            padding: 10px 20px;
            border: 2px solid transparent;
            a,p{
                color:white;
                text-decoration: none;
                font-size: 14px;
                padding:3px;
                cursor: pointer;
                transition: color 0.3s ease, transform 0.3s ease;
                &:hover {
                    // border-bottom: 3px solid white;
                  
                }


            }

            &:hover {
                > div{
                    display: flex;
                }
            }
            div{
                position: absolute;
                @include display_as_column(); 
                width:300px;
                background-color: $main-background;
                // left:0;
                top:45px;
                display: none;
                a{
                    transition: background-color 0.3s ease, transform 0.3s ease;
                    text-decoration: none;
                    border:none;
                    padding:10px;
                    top:50px;

                    &:hover{
                        background-color: $secondry-background;
                    }
                }
            }

           
          }
        }
    }

    .language-prompt{
        position: absolute;
        @include display_as_column_center();
      
        width:100%;
        height:100%;
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.342);
        section{
            padding:20px;
            @include display_as_column_center();
            border:1px solid rgb(34, 34, 34);
            border-radius: 5px;
            background-color: rgba(245, 240, 240, 0.959);
            width:300px;
            height:250px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s ease;
        }
         button {
            margin: 10px auto;
            width:200px;
            padding:10px;
            border:none;
            cursor: pointer;
            font-weight: bold;
            background-color: black;
            color:white;
            border-radius: 5px;
            @include display_as_row_center();

            > img {
                width: 20px;
                margin-right:15px;
            }
        }

        // left:calc(50% - 150px);
        // top:calc(50% - 150px);
        z-index: 2;

    }
}


@media only screen and (max-width: 650px) {
    header {
        .bottom {
            flex-direction: column;
            z-index: 21; 
            // align-items: flex-end;
            // justify-content: flex-end;
            padding:10px;
            // @
            button {
                display: flex;
            }
            nav {
                background-color: $secondry-background;
                @include display_as_column();
                background-color: $main-background; 
                // position: absolute;
                // top:47px;
                cursor: pointer;
                width:100%;
                right:0px;
                section {
                    div{
                        position: inherit;
                        width:auto;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 450px){  
    header{
        .center{
            padding:10px;
            .logo_name{
             @include display_as_column_center();

            }
            @include display_as_column_center();
        }
        .bottom {
            // display: none;
            display: flex;
            flex-wrap: wrap;
        }
    }
}