@import './Utills.scss';

.loader {
    background-color: rgb(251, 254, 255);
    width:100%;
    height:100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img{
        width: 250px;
        font-family: 'Inter';
        font-weight: bold; 
        mix-blend-mode: multiply;
    }

}