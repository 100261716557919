// MessagePage.scss
@import './Color.scss';

.message-page {
  padding: 4rem 2rem;
  background-color: #fff;

  .container {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }

  .message-title {
    font-size: 2.5rem;
    color: $main-background;
    margin-bottom: 2rem;
    font-weight: 700;
    letter-spacing: -0.5px;
  }

  .profile-section {
    margin-bottom: 2rem;

    .profile-image {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      object-fit: cover;
      margin-bottom: 1rem;
    }

    h3 {
      font-size: 1.8rem;
      color: $secondry-background;
      font-weight: 600;
    }

    .designation {
      font-size: 1rem;
      color: $ternary-background;
      font-weight: 500;
      text-transform: uppercase;
      margin-top: 0.5rem;
    }
  }

  .message-text {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #444;
    text-align: justify;
    margin-top: 1rem;
    font-style: italic;

    &::before {
      content: '"';
      font-size: 3rem;
      color: $ternary-background;
      opacity: 0.2;
      position: absolute;
      margin-left: -20px;
    }
  }
}
