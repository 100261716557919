$main-background: rgb(6, 53, 85);
$secondary-background: rgb(5, 87, 102);
$ternary-background: rgb(255, 120, 31);

.school-footer {
  background-color: $main-background;
  color: white;
  padding: 2rem 1rem;
  font-family: Arial, sans-serif;

  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }

  .footer-section {
    flex: 1;
    min-width: 250px;
    margin-bottom: 1rem;

    h3 {
      color: $ternary-background;
      margin-bottom: 1rem;
      font-size: 1.2rem;
    }

    nav {
      display: flex;
      flex-direction: column;

      a {
        color: white;
        text-decoration: none;
        margin-bottom: 0.5rem;
        transition: color 0.3s ease;

        &:hover {
          color: $ternary-background;
        }
      }
    }
  }

  .logo-section {
    img {
      width: 80px;
      height: auto;
      margin-bottom: 1rem;
    }

    h2 {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 0.9rem;
      margin-bottom: 0.3rem;
    }
  }

  .social-icons {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;

    .social-icon {
      img {
        width: 30px;
        height: 30px;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .footer-bottom {
    text-align: center;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    p {
      font-size: 0.9rem;
    }

    .language-selector {
      display: flex;
      gap: 1rem;

      button {
        background: none;
        border: none;
        color: white;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 0.9rem;

        img {
          width: 20px;
          height: auto;
        }

        &:hover {
          color: $ternary-background;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .school-footer {
    .footer-content {
      flex-direction: column;
    }

    .footer-section {
      margin-bottom: 2rem;
    }

    .footer-bottom {
      flex-direction: column;
      gap: 1rem;
    }
  }
}