// Variables
$primary-color: rgb(6, 53, 85);
$secondary-color: rgb(6, 53, 85)0;
$background-color: #f8f9fa;
$text-color: #333;
$border-radius: 8px;

// Mixins
@mixin box-shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@mixin transition {
  transition: all 0.3s ease;
}

.fee-structure {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: $text-color;
  background-color: $background-color;
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;

  h1 {
    color: $secondary-color;
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
  }

  h2 {
    color: $primary-color;
    margin-top: 30px;
    margin-bottom: 15px;
    text-align: center;
    font-size: 20px;
  }

  section {
    background-color: #fff;
    border-radius: $border-radius;
    padding: 20px;
    margin-bottom: 20px;
    @include box-shadow;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 15px;

    th, td {
      padding: 10px;
      text-align: left;
      border-bottom: 1px solid #e0e0e0;
    }

    th {
      background-color: $primary-color;
      color: #fff;
      font-weight: bold;
      font-size: 14px;
    }

    tr:last-child td {
      border-bottom: none;
    }
  }

  // Responsive styles
  @media screen and (max-width: 768px) {
    padding: 10px;

    h1 {
      font-size: 22px;
    }

    h2 {
      font-size: 18px;
    }

    section {
      padding: 15px;
    }

    table {
      font-size: 14px;

      thead {
        display: none;
      }

      tr {
        display: block;
        margin-bottom: 15px;
        border: 1px solid #e0e0e0;
        border-radius: $border-radius;
      }

      td {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid #e0e0e0;

        &:before {
          content: attr(data-label);
          font-weight: bold;
          margin-right: 10px;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}