// Color Variables
$main-background: rgb(6, 53, 85);
$secondary-background: rgb(5, 87, 102);
$tertiary-background: rgb(255, 120, 31);
$white: #ffffff;
$light-gray: #f4f4f4;
$text-color: #333;

// Responsive Mixins
@mixin mobile {
    @media (max-width: 768px) {
        @content;
    }
}

// Reset and Base Styles
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background-color: $light-gray;
    color: $text-color;
}

.contact-section {
    padding: 50px 20px;
    background-color: $white;

    .contact-container {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        gap: 30px;

        @include mobile {
            flex-direction: column;
            gap: 20px;
        }

        .contact-details {
            flex: 1;
            background-color: $white;
            padding: 30px;
            border-radius: 10px;

            h2 {
                color: $main-background;
                margin-bottom: 30px;
                text-align: center;
                border-bottom: 3px solid $tertiary-background;
                padding-bottom: 10px;
            }

            .contact-info-block {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .contact-info-item {
                    display: flex;
                    align-items: flex-start;
                    gap: 15px;
                    padding: 15px;
                    background-color: $light-gray;
                    border-radius: 8px;
                    transition: background-color 0.3s ease;

                    &:hover {
                        background-color: darken($light-gray, 5%);
                    }

                    i {
                        font-size: 2rem;
                        color: $tertiary-background;
                        min-width: 40px;
                    }

                    div {
                        flex-grow: 1;

                        h3 {
                            color: $secondary-background;
                            margin-bottom: 5px;
                        }

                        p {
                            margin-bottom: 3px;
                            color: darken($text-color, 10%);
                        }
                    }
                }
            }
        }

        .location-map {
            flex: 1;
            background-color: $white;
            padding: 30px;
            border-radius: 10px;

            h3 {
                color: $main-background;
                margin-bottom: 20px;
                text-align: center;
                border-bottom: 3px solid $tertiary-background;
                padding-bottom: 10px;
            }

            iframe {
                border-radius: 10px;
                width: 100%;
                
                @include mobile {
                    height: 250px !important;
                }
            }
        }
    }
}

// Fallback for icons (in case icon font is not used)
.icon-location::before { content: "📍"; }
.icon-phone::before { content: "📞"; }
.icon-email::before { content: "✉️"; }