$main-background: rgb(6, 53, 85);
$secondary-background: rgb(5, 87, 102);
$tertiary-background: rgb(255, 120, 31);

// Mixins
@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin mediaQuery($width) {
  @media (max-width: $width) {
    @content;
  }
}

.testimonials-section {
  background: linear-gradient(135deg, $main-background, darken($main-background, 10%));
  padding: 2rem 2rem; // Reduced vertical padding
  min-height: auto; // Removed full viewport height
  @include flexCenter;

  .testimonials-container {
    width: 100%;
    max-width: 1400px;
    
    .section-header {
      @include flexCenter;
      flex-direction: column;
      margin-bottom: 2rem; // Reduced margin
      text-align: center;
      
      .header-icon {
        color: $tertiary-background;
        width: 3rem;
        height: 3rem;
        margin-bottom: 0.5rem;
        opacity: 0.7;
      }

      h2 {
        color: white;
        font-size: 2.5rem;
        text-transform: uppercase;
        letter-spacing: -1px;
        margin-bottom: 0.5rem;
      }

      p {
        color: rgba(255,255,255,0.7);
        font-size: 1rem;
        max-width: 600px;
      }
    }

    .testimonial-slider {
      @include flexCenter;
      position: relative;
      gap: 2rem;

      .nav-btn {
        background-color: rgba($tertiary-background, 0.1);
        border: none;
        color: white;
        padding: 1rem;
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.3s ease;
        z-index: 10;

        &:hover {
          background-color: rgba($tertiary-background, 0.2);
          transform: scale(1.1);
        }

        svg {
          width: 2rem;
          height: 2rem;
        }
      }

      .testimonial-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr); // Changed to 2 columns
        gap: 1.5rem;
        width: 100%;
        justify-content: center; // Center the grid items
        align-items: center;

        @include mediaQuery(1024px) {
          grid-template-columns: 1fr;
        }

        .review-image-container {
          border-radius: 16px;
          overflow: hidden;
          box-shadow: 0 15px 35px rgba(0,0,0,0.3);
          transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94); // Smoother animation
          
          &:hover {
            transform: scale(1.03);
          }

          .review-image {
            width: 100%;
            height: auto;
            display: block;
            object-fit: cover;
          }
        }
      }
    }

    .review-indicator {
      @include flexCenter;
      margin-top: 1rem; // Reduced margin
      gap: 0.75rem;

      .dot {
        width: 12px;
        height: 12px;
        background-color: rgba(255,255,255,0.3);
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.3s ease;

        &.active, &:hover {
          background-color: $tertiary-background;
          width: 20px;
          border-radius: 10px;
        }
      }
    }
  }

  // Responsive adjustments
  @include mediaQuery(1024px) {
    .testimonials-container {
      .testimonial-slider {
        flex-direction: column;

        .nav-btn {
          display: none; // Hide navigation buttons on smaller screens
        }
      }
    }
  }
}
