@import './Color.scss';

// @import url('https:fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
// @import url('https:cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');
// @import url('https:fonts.googleapis.com/css2?family=Heebo:wght@100;300;400;600;700;900&display=swap');
// @import url('https:fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200&display=swap');
// @import url('https:fonts.googleapis.com/css2?family=Monoton&display=swap');
// @import url('https:fonts.googleapis.com/css2?family=Economica:ital,wght@0,400;0,700;1,400;1,700&family=Faster+One&family=Salsa&display=swap');

@import url("https:fonts.googleapis.com/css?family=Abel|Abril+Fatface|Acme|Alegreya|Alegreya+Sans|Anton|Archivo|Archivo+Black|Archivo+Narrow|Arimo|Arvo|Asap|Asap+Condensed|Bitter|Bowlby+One+SC|Bree+Serif|Cabin|Cairo|Catamaran|Crete+Round|Crimson+Text|Cuprum|Dancing+Script|Dosis|Droid+Sans|Droid+Serif|EB+Garamond|Exo|Exo+2|Faustina|Fira+Sans|Fjalla+One|Francois+One|Gloria+Hallelujah|Hind|Inconsolata|Indie+Flower|Josefin+Sans|Julee|Karla|Lato|Libre+Baskerville|Libre+Franklin|Lobster|Lora|Mada|Manuale|Maven+Pro|Merriweather|Merriweather+Sans|Montserrat|Montserrat+Subrayada|Mukta+Vaani|Muli|Noto+Sans|Noto+Serif|Nunito|Open+Sans|Open+Sans+Condensed:300|Oswald|Oxygen|PT+Sans|PT+Sans+Caption|PT+Sans+Narrow|PT+Serif|Pacifico|Passion+One|Pathway+Gothic+One|Play|Playfair+Display|Poppins|Questrial|Quicksand|Raleway|Roboto|Roboto+Condensed|Roboto+Mono|Roboto+Slab|Ropa+Sans|Rubik|Saira|Saira+Condensed|Saira+Extra+Condensed|Saira+Semi+Condensed|Sedgwick+Ave|Sedgwick+Ave+Display|Shadows+Into+Light|Signika|Slabo+27px|Source+Code+Pro|Source+Sans+Pro|Spectral|Titillium+Web|Ubuntu|Ubuntu+Condensed|Varela+Round|Vollkorn|Work+Sans|Yanone+Kaffeesatz|Zilla+Slab|Zilla+Slab+Highlight|Noto+Sans+Mono");

// @import url('https:fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Bungee&family=Bungee+Inline&family=Bungee+Shade&family=Carter+One&family=Faster+One&family=Fredoka+One&family=Lobster&family=Monoton&family=Orbitron&family=Ramaraja&family=Rammetto+One&family=Rampart+One&family=Russo+One&family=Sancreek&family=Secular+One&family=Sigmar+One&family=Vast+Shadow&family=Zen+Tokyo+Zoo&display=swap');

// @import url('https:fonts.googleapis.com/css2?family=Aldrich&family=Alegreya+Sans+SC&family=Federo&family=Pattaya&family=Roboto+Condensed:wght@300;400&display=swap');

html {
    scroll-behavior: smooth;
}

*{
    scroll-behavior: smooth;

    margin:0;
    padding:0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    font-family:  'Roboto';
    // overflow-x: hidden;
    // font-family: 'Mada', sans-serif ;
        // font-family: 'Nato Sans Mono';
        // font-family: 'Rubik'
        // // overflow-x: hid;
    // fontfa
}


@mixin display_center(){
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin display_as_column(){
    display: flex;
    flex-direction: column;
}

@mixin display_as_column_center(){
    @include display_as_column;
    justify-content: center;
    align-items: center;
}

@mixin display_as_row(){
    display: flex;
    flex-direction: row;
}
@mixin display_as_row_end {
    @include display_as_row;
    justify-content: flex-end;
    align-items: center;
}
@mixin display_as_column_end {
    @include display_as_column_end;
     justify-content: flex-end;
    align-items: flex-end;
}

@mixin display_as_row_center(){
    @include display_as_row;
    justify-content: center;
    align-items: center;
}
@mixin display_as_row_space_between(){
    @include display_as_row;
    justify-content: space-between;
    align-items: center;
}
@mixin display_as_row_space_around(){
    @include display_as_row;
    justify-content: space-around;
    align-items: center;
}






 ::-webkit-scrollbar {
     width: 8px;
     height:8px;
   }
  
   ::-webkit-scrollbar-thumb {
     background: $main-background;
     border-radius: 20px;
    //  border: solid 4px $nav_background;
     background-clip: padding-box;
   }
    
   ::-webkit-scrollbar-track {
     background: transparent; 
   }