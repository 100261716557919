// Color Variables
$main-background: #f9f9f9;
$secondary-background: #ffffff;
$primary-color: #374b6e;
$accent-color: #ffa500;
$text-color-dark: #333;
$text-color-muted: #666;

// Mixins
@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin mediaQuery($width) {
  @media (max-width: $width) {
    @content;
  }
}

// Styles
.about-school-section {
  background: $main-background;
  padding: 4rem 2rem;

  .about-school-container {
    max-width: 1200px;
    margin: 0 auto;
    color: $text-color-dark;

    .school-intro {
      display: flex;
      align-items: center;
      gap: 2rem;
      margin-bottom: 3rem;

      @include mediaQuery(768px) {
        flex-direction: column;
        text-align: center;
      }

      .school-logo-container {
        flex: 0 0 250px;
        max-width: 250px;

        @include mediaQuery(768px) {
          flex: 0 0 150px;
        }

        .school-logo {
          width: 100%;
          height: auto;
          border-radius: 12px;
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
        }
      }

      .school-description {
        flex: 1;

        h2 {
          font-size: 2.2rem;
          color: $primary-color;
          margin-bottom: 1rem;
          text-transform: uppercase;
          letter-spacing: 1px;
        }

        p {
          line-height: 1.6;
          font-size: 1.1rem;
          color: $text-color-muted;
        }
      }
    }

    .school-highlights {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;

      @include mediaQuery(1024px) {
        grid-template-columns: 1fr;
      }

      .highlight-item {
        background-color: $secondary-background;
        border-radius: 12px;
        padding: 1.5rem;
        text-align: center;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
        transition: transform 0.3s ease;

        &:hover {
          transform: translateY(-10px);
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
        }

        .highlight-icon {
          width: 3rem;
          height: 3rem;
          color: $accent-color;
          margin-bottom: 1rem;
        }

        h3 {
          color: $primary-color;
          font-size: 1.4rem;
          margin-bottom: 0.5rem;
        }

        p {
          color: $text-color-muted;
          font-size: 1rem;
          line-height: 1.5;
        }
      }
    }
  }
}
