@import './Color.scss';
@import './Utills.scss';


.hero {
    h2 {
        padding: 20px;
        font-family: 'Poppins'; 
        font-weight: bold;
        width: 400px;
        text-align: center;
        font-size: 30px;
        color: rgb(39, 39, 39);
    }
    @include display_as_column();
    // background-color: aliceblue;
    .firstSection {
        background: url('../../public/images/whiteBackground.png');
        background-size: cover;
        width:100%;
        padding:15px auto;
        background-repeat: no-repeat;
        @include display_as_row_center(); 
       
        div{
            // background: $ternary-background;
            // background:linear-gradient(rgb(245, 86, 13) 10%, rgb(90, 30, 2) );
            // width:300px;
            height:330px; 
            @include display_as_column;
            align-items: flex-end;
            justify-content: flex-end;
            margin-top: 20px;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            // box-shadow: -2px -6px 5px 5px rgb(206, 206, 206);

            img{
                width:450px;
                filter: brightness(1.1) contrast(1.2);
            }
        }
    } 

    .playSection{
        background: linear-gradient(110deg, rgb(1, 64, 75) , rgb(28, 117, 133) 10%, rgb(1, 40, 48));
        @include display_as_row_center(); 
        padding:20px;
        img{
            width:500px;
            margin:20px;
            border-radius: 20px;
            border:3px solid rgb(223, 223, 223);
            filter: brightness(1.2) contrast(1.0);

        }
        h2{
            color:white;
          
        }

        center {
            font-size: 18px;
            background-color: rgba(0, 0, 0, 0.404);
            padding:7px 10px; 
            border-radius: 15px;
            margin:1px auto; 
            font-weight: bold;
            color: $ternary-background;
            width:320px;
            text-shadow: 2px 2px 2px  black; 

        }
    }

    .video_section {
        width: 100%;
        @include display_as_column_center();
        background-image: url('https://static.vecteezy.com/system/resources/thumbnails/000/275/717/small/White_Background-01.jpg');
        video {
            max-width: 750px;
        }
    }
}



@media only screen and (max-width: 900px) {
   .hero{
    
    h2{
        font-size: 25px;
    }
    img{
        width: 300px;
    }
    .firstSection, .playSection {
        @include display_as_column_center();

    }

    .firstSection > div {
        height: auto;
        width:auto;
    }
    .playSection {
        flex-direction: column-reverse;
    }
   }
}

@media only screen and (max-width: 800px) {
    .video_section {
        video {
            width:100%;
        }
    }
}
@media only screen and (max-width: 500px){
    center, h2{
        font-size: 20px!important;
        width:auto !important;
    }
    .firstSection {
        div {
            img {
                width: 95vw!important;
            }
        }
    }
    .playSection {
        center{
            font-size: 14px !important;
        }
        img{
            width: 95vw!important;
        }
    }
}
