// MessageSection.scss
@import './Color.scss';

.message-section {
  padding: 4rem 2rem;
  background-color: #fff;
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(90deg, transparent, $ternary-background, transparent);
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .header-container {
    text-align: center;
    max-width: 600px;
    margin: 0 auto 4rem auto;
  }

  .section-title {
    color: $main-background;
    font-size: 2.8rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
    letter-spacing: -0.5px;
  }

  .section-subtitle {
    color: $secondry-background;
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: 1px;
  }

  .message-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    gap: 3rem;
    justify-content: center;
    padding: 0.5rem;
  }

  .message-card {
    background: white;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba($main-background, 0.1);
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    display: flex;
    flex-direction: row;
    height: 280px;
    
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 15px 40px rgba($main-background, 0.15);

      .profile-image {
        transform: scale(1.08);
      }

      .read-more-btn {
        background-position: right center;

       
      }
    }

    .image-container {
      width: 280px;
      flex-shrink: 0;
      position: relative;
      overflow: hidden;
      
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
          90deg,
          transparent 0%,
          rgba($main-background, 0.2) 70%,
          rgba($main-background, 0.4) 100%
        );
        z-index: 1;
      }
      
      .profile-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.8s ease;
      }
    }

    .content {
      padding: 1.5rem;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      background: linear-gradient(
        165deg,
        white 0%,
        rgba($secondry-background, 0.05) 100%
      );

      .profile-info {
        margin-bottom: 1rem;
        
        h4 {
          color: $secondry-background;
          font-size: 1.3rem;
          margin-bottom: 0.5rem;
          font-weight: 600;
        }

        .designation {
          display: inline-block;
          color: $ternary-background;
          font-size: 0.9rem;
          text-transform: uppercase;
          letter-spacing: 2px;
          font-weight: 600;
          padding: 0.2rem 0.8rem;
          border: 1px solid rgba($ternary-background, 0.3);
          border-radius: 15px;
        }
      }

      .preview-text {
        color: #444;
        line-height: 1.6;
        font-size: 0.95rem;
        position: relative;
        font-style: italic;
        margin-bottom: 1rem;
        flex-grow: 1;
        
        &::before {
          content: '"';
          position: absolute;
          top: -15px;
          left: -5px;
          color: $ternary-background;
          font-size: 3rem;
          opacity: 0.2;
          font-family: Georgia, serif;
        }
      }

      .read-more-btn {
        align-self: flex-start;
        background: linear-gradient(to right, $ternary-background 0%, darken($ternary-background, 10%) 50%, $ternary-background 100%);
        background-size: 200% auto;
        color: white;
        border: none;
        padding: 0.5rem 1.2rem;
        border-radius: 20px;
        cursor: pointer;
        font-weight: 500;
        font-size: 0.9rem;
        letter-spacing: 0.5px;
        transition: 0.5s ease;
        a {
          color: white;
          text-decoration: none;
        }
        
        &:hover {
          background-position: right center;
        }
      }
    }
  }
}

// Media Queries
@media (max-width: 1024px) {
  .message-section {
    .message-card {
      height: 240px;
      
      .image-container {
        width: 220px;
      }
    }
  }
}

@media (max-width: 768px) {
  .message-section {
    padding: 3rem 1.5rem;

    .section-title {
      font-size: 2.3rem;
    }

    .message-cards {
      grid-template-columns: 1fr;
      max-width: 500px;
      margin: 0 auto;
      gap: 2rem;
    }
    
    .message-card {
      height: auto;
      flex-direction: column;
      
      .image-container {
        width: 100%;
        height: 200px;
        
        .overlay {
          background: linear-gradient(
            180deg,
            transparent 0%,
            rgba($main-background, 0.2) 70%,
            rgba($main-background, 0.4) 100%
          );
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .message-section {
    padding: 2rem 1rem;

    .section-title {
      font-size: 2rem;
    }

    .section-subtitle {
      font-size: 1rem;
      margin-bottom: 2rem;
    }

    .message-card {
      .content {
        padding: 1.2rem;
        
        .profile-info {
          h4 {
            font-size: 1.2rem;
          }

          .designation {
            font-size: 0.85rem;
          }
        }

        .preview-text {
          font-size: 0.9rem;
        }
      }
    }
  }
}